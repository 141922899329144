/* eslint-disable no-restricted-syntax */
import { DataField } from "@iventis/domain-model/model/dataField";
import { v4 as uuid } from "uuid";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { convertKeyValuePairToMap, replaceDatafieldAndListItemIdsInStyle } from "@iventis/utilities";
import { DataFieldListItemPropertyValue } from "@iventis/domain-model/model/dataFieldListItemPropertyValue";
import { DataFieldListItemRelationshipValue } from "@iventis/domain-model/model/dataFieldListItemRelationshipValue";
import { ImportMapApiFunctions, ImportMapLayerDataField, MapImportLayer } from "./map-json-types";
import { replaceLayerMapObjectIds } from "./map-json-map-objects";
import { removeTimeZoneFromDate } from "./map-json-helpers";

/** Replaces the data field and list item ids for a layer */
export async function replaceDataFieldAndMapObjectIdsForLayer(layer: MapImportLayer, projectDataFields: DataField[], apiFunctions: ImportMapApiFunctions): Promise<MapImportLayer> {
    // Create new ids for the datafields and list items
    const updatedDataFieldIds = createNewDataFieldIds(layer.dataFields, projectDataFields);
    const updatedListItemIds = await createNewListItemIds(layer.dataFields, projectDataFields, apiFunctions);

    layer.dataFields.forEach((df) => {
        if (df.type === DataFieldType.Date && df.defaultValue.valueDate != null) {
            // eslint-disable-next-line no-param-reassign
            df.defaultValue.valueDate = removeTimeZoneFromDate(df.defaultValue);
        }
    });

    // For each datafield replace it's ids and the ids of the list items
    const updatedDataFields = layer.dataFields.map((df) => replaceDataFieldAndListItems(df, updatedDataFieldIds, updatedListItemIds, projectDataFields));
    // Replace the ids in the style
    const updatedLayer = replaceDatafieldAndListItemIdsInStyle<MapImportLayer>(
        layer,
        convertKeyValuePairToMap(updatedDataFieldIds),
        convertKeyValuePairToMap(updatedListItemIds),
        (layer) => layer.style
    );

    const updatedMapObjectLayers = replaceLayerMapObjectIds(layer, updatedDataFieldIds, updatedListItemIds);

    return { ...updatedLayer, style: updatedLayer.style, dataFields: updatedDataFields, mapObjects: updatedMapObjectLayers } as MapImportLayer;
}

/** Creates a key value pair with old and new datafield ids */
function createNewDataFieldIds(dataFields: ImportMapLayerDataField[], projectDataFields: DataField[]) {
    const updatedDataFieldIds: { [id: string]: string } = {};
    dataFields.forEach((df) => {
        if (df.systemDataFieldName != null) {
            updatedDataFieldIds[df.id] = projectDataFields.find((dataField) => dataField.systemDataFieldName === df.systemDataFieldName)?.id;
        } else if (df.type === DataFieldType.What3Words) {
            updatedDataFieldIds[df.id] = projectDataFields.find((dataField) => dataField.type === DataFieldType.What3Words)?.id;
            // If the datafield is a project datafield, we want to keep the same id
        } else if (projectDataFields.some((pdf) => pdf.id === df.id)) {
            const projectDataField = projectDataFields.find((pdf) => pdf.id === df.id);
            updatedDataFieldIds[df.id] = projectDataField?.id;
        } else {
            updatedDataFieldIds[df.id] = uuid();
        }
    });
    return updatedDataFieldIds;
}

/** Creates a key value pair with old and new list item ids */
async function createNewListItemIds(dataFields: ImportMapLayerDataField[], projectDataFields: DataField[], apiFunctions: ImportMapApiFunctions) {
    const updatedListItemIds: { [id: string]: string } = {};
    for (const df of dataFields) {
        if (df.type === DataFieldType.List) {
            const isProjectDataField = projectDataFields.some((pdf) => pdf.id === df.id);

            if (isProjectDataField) {
                const listItems = await apiFunctions.getListItems(df.id);
                df.listItems.forEach((li) => {
                    // Find list item by it unique id
                    const listItem = listItems.find((item) => item.uniqueId === li.uniqueId);
                    if (listItem == null) {
                        throw new Error(`List item can't be found Unique id:"${li.uniqueId}" List item name:"${li.name}" List item id:"${li.id}"`);
                    } else {
                        updatedListItemIds[li.id] = listItem.id;
                    }
                });
            } else {
                df.listItems.forEach((li) => {
                    updatedListItemIds[li.id] = uuid();
                });
            }
        }
    }
    return updatedListItemIds;
}

/** Creates a key value pair with old and new property value ids  */
function createNewPropertyValueIds(dataField: ImportMapLayerDataField) {
    const updatedPropertyValueIds: { [id: string]: string } = {};
    dataField.listItemProperties?.forEach((propertyValue) => {
        updatedPropertyValueIds[propertyValue.id] = uuid();
    });
    return updatedPropertyValueIds;
}

/** Creates a key value pair with old and new relationship ids */
function createNewRelationshipValues(dataField: ImportMapLayerDataField) {
    const updateRelationshipValues: { [id: string]: string } = {};
    dataField.listItemRelationships?.forEach((listItemRelationship) => {
        updateRelationshipValues[listItemRelationship.id] = uuid();
    });
    return updateRelationshipValues;
}

function replaceDataFieldAndListItems(
    dataField: ImportMapLayerDataField,
    updatedDataFieldIds: { [id: string]: string },
    updatedListItemIds: { [id: string]: string },
    projectDataFields: DataField[]
): ImportMapLayerDataField {
    if (dataField.type === DataFieldType.List) {
        const isProjectDataField = projectDataFields.some((pdf) => pdf.id === dataField.id);
        if (!isProjectDataField) {
            return replaceIdsInListItemDataField(dataField, updatedDataFieldIds, updatedListItemIds);
        }
        return {
            ...dataField,
            id: updatedDataFieldIds[dataField.id],
            listItems: dataField.listItems.map((li) => ({ ...li, id: updatedListItemIds[li.id] })),
            defaultValue: {
                ...dataField.defaultValue,
                dataFieldId: updatedDataFieldIds[dataField.defaultValue?.dataFieldId],
                listItemId: updatedListItemIds[dataField.defaultValue?.listItemId],
            },
        };
    }

    return {
        ...dataField,
        defaultValue: {
            ...dataField.defaultValue,
            valueRepeatedTimeRanges: dataField?.defaultValue?.valueRepeatedTimeRanges?.map((valueRepeatedTimeRange) => ({ ...valueRepeatedTimeRange, id: uuid() })),
            dataFieldId: updatedDataFieldIds[dataField.defaultValue?.dataFieldId],
        },
        id: updatedDataFieldIds[dataField.id],
    };
}

/** Replaces ids for the datafield, list items, relationships and properties for a list item */
function replaceIdsInListItemDataField(
    dataField: ImportMapLayerDataField,
    updatedDataFieldIds: { [id: string]: string },
    updatedListItemIds: { [id: string]: string }
): ImportMapLayerDataField {
    // Create new ids for the properties and relationships
    const updatedPropertyIds = createNewPropertyValueIds(dataField);
    const updatedRelationshipIds = createNewRelationshipValues(dataField);
    // Replace all the ids within the datafield
    return {
        ...dataField,
        id: updatedDataFieldIds[dataField.id],
        defaultValue: {
            ...dataField.defaultValue,
            dataFieldId: updatedDataFieldIds[dataField.defaultValue?.dataFieldId],
            listItemId: updatedListItemIds[dataField.defaultValue?.listItemId],
        },
        listItemProperties: dataField.listItemProperties?.map((property) => ({
            name: property.name,
            type: property.type,
            dataFieldId: updatedDataFieldIds[property.dataFieldId],
            id: updatedPropertyIds[property.id],
        })),
        listItemRelationships: dataField.listItemRelationships?.map((relationship) => ({
            id: updatedRelationshipIds[relationship.id],
            dataFieldId: updatedDataFieldIds[relationship.dataFieldId],
            relatedToDataFieldId: updatedDataFieldIds[relationship.relatedToDataFieldId],
        })),
        listItems: dataField.listItems.map((listItem) => {
            const propertyValues: DataFieldListItemPropertyValue[] = listItem.propertyValues.map((propertyValue) => ({
                dataFieldListItemId: updatedListItemIds[propertyValue.dataFieldListItemId],
                propertyId: updatedPropertyIds[propertyValue.propertyId],
                text: propertyValue.text,
                number: propertyValue.number,
            }));

            const relationshipValues: DataFieldListItemRelationshipValue[] = listItem.relationshipValues.map((relationshipValue) => ({
                dataFieldListItemId: updatedListItemIds[relationshipValue.dataFieldListItemId],
                relationshipId: updatedRelationshipIds[relationshipValue.relationshipId],
                relatedToDataFieldListItemId: updatedListItemIds[relationshipValue.relatedToDataFieldListItemId],
            }));

            return {
                id: updatedListItemIds[listItem.id],
                dataFieldId: updatedDataFieldIds[listItem.dataFieldId],
                propertyValues,
                relationshipValues,
                name: listItem.name,
                order: listItem.order,
                uniqueId: listItem.uniqueId,
            };
        }),
    };
}
