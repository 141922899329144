import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useIsElementScrollable } from "@iventis/utilities";
import { Theme } from "@emotion/react";
import { StyledCustomDialog } from "@iventis/components";
import { LayerEditComponent, LayerEditComponentProps } from "./edit-layer";

interface LayerEditModalProps extends LayerEditComponentProps {
    open: boolean;
    showTour: boolean;
    finishTour: () => void;
    // skipTemplateSelection: boolean;
}

export const LayerEditModal: React.FC<LayerEditModalProps> = ({
    layer,
    mapName,
    open,
    categoryOptions,
    isTemplateSelector,
    allowImageUpload,
    showTour,
    finishTour,
    thumbnailImage,
    navigateToProjectSettings,
    canUserEdit,
    close,
    skipTemplateSelection,
}) => {
    const [enlarged, enlarge] = useState(false);
    const { elementRef, isElementScrollable } = useIsElementScrollable();
    const theme = useTheme<Theme>();
    return (
        <StyledCustomDialog
            $staticHeight="80vh"
            $alternativePadding={false}
            open={open}
            maxWidth="md"
            fullWidth={enlarged}
            className="LayerEditModal"
            onClose={close}
            stopPropagation
            $overflow="auto"
            $trackColour={isElementScrollable ? theme.shades.four : theme.typographyColors.blank}
        >
            <LayerEditComponent
                mapName={mapName}
                layer={layer}
                isTemplateSelector={isTemplateSelector}
                allowImageUpload={allowImageUpload}
                enlarge={enlarge}
                categoryOptions={categoryOptions}
                showTour={showTour}
                thumbnailImage={thumbnailImage}
                finishTour={finishTour}
                navigateToProjectSettings={navigateToProjectSettings}
                canUserEdit={canUserEdit}
                contentRefContainerRef={elementRef}
                close={close}
                skipTemplateSelection={skipTemplateSelection}
            />
        </StyledCustomDialog>
    );
};
