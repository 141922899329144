import { IAssetService } from "@iventis/components";
import { Asset } from "@iventis/domain-model/model/asset";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { BasicMapLayer } from "@iventis/map-engine";
import { getLayerStyle } from "@iventis/map-engine/src/utilities/layer.helpers";
import { EMPTY_GUID, imageBlobResizer, getAssetSignature } from "@iventis/utilities";
import { findDifferingStyleProperties } from "@iventis/map-engine/src/utilities/style-helpers";
import { AxiosResponse } from "axios";
import { DataField } from "@iventis/domain-model/model/dataField";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { v4 as uuid } from "uuid";
import { IStyleService } from "@iventis/layer-styles";
import { Hierarchy } from "@iventis/domain-model/model/hierarchy";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { api } from "../api/api";
import { getAsset, getAssets, getModels, getModel } from "./assets-api-helpers";

const imageCompressUrlGetter = async (id: string, pixelRes: number): Promise<string> => {
    const asset = await getAsset(id);
    const response = await api.get(getAssetSignature(asset.assetUrl, asset.authoritySignature), {
        responseType: "blob",
        // If withCredentials is not set to false the icons don't load in Mapbox
        withCredentials: false,
    });
    return imageBlobResizer(response.data, pixelRes);
};

export const assetService: IAssetService = {
    getAsset,
    getAssetTags: async () => {
        const response = await api.get<Hierarchy<AssetCategory>[]>("categories");
        return response.data;
    },
    getAssetsById: async (ids) => {
        const assets = await Promise.all(ids.map((id) => getAsset(id)));
        return assets;
    },
    getAssetsByType: async (pageNumber, assetType, filters) => {
        const assets = (
            await getAssets({
                filter: filters.concat([{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: assetType }]),
            })
        ).filter((a) => a.name !== "model lod file"); // Remove this filtering once we have a better way of fetching the thumbnails for models
        // Mock pagaing for now as it is not impemented on the Admin Dashboard Api
        const pagedAsset: PagedResult<Asset[]> = { pageNumber, pageSize: assets.length, lastPage: true, results: assets };
        return pagedAsset;
    },
    getCdnAsset: async <T>(url: string) => {
        const res = await api.get<T>(url, { withCredentials: false });
        return res.data;
    },
    // Admin dashboard already handles icon upload
    postIcon: () => undefined,
    // Admin dashboard already handles Model upload
    postModel: () => undefined,
    patchIcon: () => undefined,
    canEditAsset: () => true,
    updateModel: () => undefined,
    deleteAsset: () => undefined,
    hideAssetSelectorActions: true,
    getRecommendedCategories: () => undefined,
};

export const editStyleService: IStyleService = {
    getLayerStyle: async (layerId: string) => {
        const templateLayerAsset = await getAsset(layerId);
        const { data: layerJson } = await api.get<string, AxiosResponse<string>>(getAssetSignature(templateLayerAsset.assetUrl, templateLayerAsset.authoritySignature));
        const layer = JSON.parse(layerJson) as MapLayer;
        return getLayerStyle(layer);
    },
    getModels: () => getModels({ filter: [] }),
    modelGetter: async (modelIds: string[]) => {
        const modelRequests = modelIds.map((id) => getModel(id));
        const response = await Promise.all(modelRequests);
        return response;
    },
    assetGetter: async (ids: string[]) => {
        const assetRequets = ids.map((assetId) => getAsset(assetId));
        const response = await Promise.all(assetRequets);
        return response.map(({ id, assetUrl, authoritySignature }) => ({ id, url: getAssetSignature(assetUrl, authoritySignature) }));
    },
    getProjectDataFields: async () => {
        const { data } = await api.post<DataField[]>("data-fields/filter", []);
        return [
            ...data,
            mapObjectNameSystemDataField,
            mapObjectAreaSystemDataField,
            mapObjectLengthSystemDataField,
            mapObjectCoordinateSystemDataField,
            mapObjectRouteTimeSystemDataField,
        ];
    },
    imageCompressor: imageCompressUrlGetter,
    postProjectAttribute(): Promise<DataField> {
        throw new Error("Function not implemented.");
    },
    patchProjectAttribute(): Promise<DataField> {
        throw new Error("Function not implemented.");
    },
    isAssetSdf: async () => false,
    bustCacheIds: () => null,
};

export const isLayerTemplateStyleChanged = (originalLayer: BasicMapLayer, editedLayer: BasicMapLayer): boolean => {
    const editLayerStyle = getLayerStyle(editedLayer);
    const originalLayerStyle = getLayerStyle(originalLayer);
    const differingStyle = findDifferingStyleProperties(editLayerStyle, originalLayerStyle);
    return differingStyle.length > 0;
};

export const isLayerTemplateDataFieldChanged = (originalLayer: BasicMapLayer, editedLayer: BasicMapLayer): boolean =>
    JSON.stringify(originalLayer.dataFields) !== JSON.stringify(editedLayer.dataFields);

/** Because the style editor cares little for list values, when we edit the style, we must preserve the existing list values (because they are handled separately) */
export const preserveDataFieldListItemsOnLayer = (oldLayer: MapLayer, newLayer: MapLayer): MapLayer => ({
    ...newLayer,
    dataFields: newLayer.dataFields.map((dataField) => ({ ...dataField, listValues: oldLayer.dataFields.find((df) => df.id === dataField.id)?.listValues ?? [] })),
});

const mapObjectNameSystemDataField: DataField = {
    // Id really doesn't matter because it will get swapped out with the project specific id when it is used
    id: uuid(),
    systemDataFieldName: SystemDataFieldName.MapObjectName,
    name: "Map Object Name",
    customerIdentifierName: undefined,
    allowedMetadataProperties: [],
    type: DataFieldType.Text,
    listValues: [],
    projectId: EMPTY_GUID,
    hierarchyMetadataPropertyName: undefined,
    showInApi: false,
    apiNameSingular: undefined,
    apiNamePlural: undefined,
    listItemProperties: [],
    listItemRelationships: [],
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};

const mapObjectAreaSystemDataField: DataField = {
    id: uuid(),
    systemDataFieldName: SystemDataFieldName.MapObjectArea,
    name: "Area",
    customerIdentifierName: undefined,
    allowedMetadataProperties: [],
    type: DataFieldType.Number,
    listValues: [],
    projectId: EMPTY_GUID,
    hierarchyMetadataPropertyName: undefined,
    showInApi: false,
    apiNameSingular: undefined,
    apiNamePlural: undefined,
    listItemProperties: [],
    listItemRelationships: [],
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};

const mapObjectLengthSystemDataField: DataField = {
    id: uuid(),
    systemDataFieldName: SystemDataFieldName.MapObjectLength,
    name: "Length",
    customerIdentifierName: undefined,
    allowedMetadataProperties: [],
    type: DataFieldType.Number,
    listValues: [],
    projectId: EMPTY_GUID,
    hierarchyMetadataPropertyName: undefined,
    showInApi: false,
    apiNameSingular: undefined,
    apiNamePlural: undefined,
    listItemProperties: [],
    listItemRelationships: [],
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};

const mapObjectRouteTimeSystemDataField: DataField = {
    id: uuid(),
    systemDataFieldName: SystemDataFieldName.RouteTime,
    name: "Route Time",
    customerIdentifierName: undefined,
    allowedMetadataProperties: [],
    type: DataFieldType.Time,
    listValues: [],
    projectId: EMPTY_GUID,
    hierarchyMetadataPropertyName: undefined,
    showInApi: false,
    apiNameSingular: undefined,
    apiNamePlural: undefined,
    listItemProperties: [],
    listItemRelationships: [],
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};

const mapObjectCoordinateSystemDataField: DataField = {
    id: uuid(),
    systemDataFieldName: SystemDataFieldName.Coordinates,
    name: "Coordinates",
    customerIdentifierName: undefined,
    allowedMetadataProperties: [],
    type: DataFieldType.Text,
    listValues: [],
    projectId: EMPTY_GUID,
    hierarchyMetadataPropertyName: undefined,
    showInApi: false,
    apiNameSingular: undefined,
    apiNamePlural: undefined,
    listItemProperties: [],
    listItemRelationships: [],
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};
